import Axios from 'axios'

const CAPITAL_KEY = 'CAPITAL_KEY';
const windowGlobal = typeof window !== 'undefined' && window;

function setCapital(dataPay) {
  if (windowGlobal) return sessionStorage.setItem(CAPITAL_KEY, JSON.stringify(dataPay))
}

function deleteCapital() {
  if (windowGlobal) sessionStorage.removeItem(CAPITAL_KEY)
}

function getCapital() {
  if (windowGlobal) return JSON.parse(sessionStorage.getItem(CAPITAL_KEY))
}


async function deleteMerchantOperation(orderId) {
  const params = {
    p_operation_number: orderId,
  }
  await Axios.post("/dbo/treasury/delete_merchant_operation", params)
}

export {
  setCapital,
  deleteCapital,
  getCapital,
  deleteMerchantOperation
}
